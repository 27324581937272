const FeelingLuckyList = [
  {
    id: 0,
    message:
      "let yourself hope for outrageously woonderful things to come to you. test the universe. say, 'universe, if i'm so good, show me. if i'm so worthy, show me, if i am a blessed being, then let the blessings start pouring into my experience. if i'm blessed, then i want to be happy'. play the game differently now to you've been playing it before. a slight shift in understanding of who you are is going to get you to where you want to be.",
  },
  {
    id: 1,
    message:
      "you are the universe experiencing itself. what does it really mean? you are a part of the divine power, divine creator, who is all-powerful, all-knowing and all-loving. you have the power to create anything you like, taking into consideration the affairs of the present moment. you are also capable of knowing and understanding anything you like, just let your gaze look there. and your purpose here is to love.",
  },
  {
    id: 2,
    message:
      "what you need now is honesty with yourself because it is going to bring you into a state of harmony. how to be honest? get into your heart. feel your heart. feel your soul. what is it saying? remember, all that is coming from you now is correct. all you need now is to be your own best friend, listen to yourself with an open mind and act accordingly, from your heart.",
  },
  {
    id: 3,
    message:
      "what is your driving force? meaning... what is getting you going? why do you do things that you do? if it is because you should and there is 'no other choice but to do what you should do', let me destroy your understanding of reality. when you act because you should, you get yourself to the situations that cage you and make you feel small. when, on the other hand, you act because you feel like it, you let yourself free, you gain more power, you start to believe in yourself and the whole world works in your favour.",
  },
  {
    id: 4,
    message:
      "every person that surrounds you is your mirror. this means that everything you notice in people, you actually have in yourself. all of the good positive things you notice in people that seem to be 'better than you' are just things that you have as your potential and you want to develop further. all of the things that annoy you in others are the things that constitute a part of your shadow self, the things that you prefer to hide, but those are also the thigns in which your power lies, so get to know yourself via really paying attention to what you notice in people around you for the people around you are your mirrors",
  },
  {
    id: 5,
    message:
      "you are the creator. congrats. you are the magician. you are the power. your world works in accordance with your rules. how do you set those rules? your thoughts. your underlying themes. what do you tell yourself? start to tell yourself that you are the winner.",
  },
  {
    id: 6,
    message:
      "right now, for you, the topic of relationships plays a very important role in moving you forward. relationship with yourself is at the core of it all. how you act with yourslf.... this is how others are treating you",
  },
  {
    id: 7,
    message:
      "do you feel it? things are being pushed into place right now. it is between you and your karma. you acted in a certain way and will be getting the results. remember to be grateful for things are comning into place now.",
  },
  {
    id: 8,
    message:
      "what do you keep thinking about? it would be helpful to write it all down. just blurt it out. there is a super cool method where you just sit and write everything that comes to mind, a free flow of your consciousness. this is how you are going to get your answers.",
  },
  {
    id: 9,
    message:
      "gratitude from the inside out. how did get yourself where you are? your own resilience, your own work ethic, your own empathy, your own smarts, your own feels. be grateful to yourself for yourself.",
  },
  {
    id: 10,
    message:
      "start moving. in any direction that feels right. physically, emotionally, mentally. be the moth that's attracted to the flame. what's the flame? you know, it is what attracts you so strongly. so let yourself lose and move. to begin with, just move your body how it wants to be moved. let it free. everything else will follow.",
  },
  {
    id: 11,
    message:
      "life picks up your ideas and develops them. you start, and the rest of your world reacts, makes things happen. life is a constant dialogue between you and the rest of the world. so, what ideas are you pushing out right now?",
  },
  {
    id: 12,
    message:
      "universe loves you. everything that surrounds you is made of love. look at that table, sky, phone, chair, door, building, tree, grass, floor, bag, books, food... everything is energy and everything is love. sit with that feeling. how do you feel now?",
  },
  {
    id: 13,
    message:
      "what is success? interestringly enough, each of us is driving towards our notions of success. what you allow yourself to think of success, this is where you will end up. it's a great time to define precisely what you understand by success for it is where you will end up.",
  },
  {
    id: 14,
    message:
      "feel or think? in other words, how do you come to the answers in life? both ways are valid, but there needs to be a balance. if you've been mostly guided by thinking, it's time to feel. if you are the type to feel, it's time to think",
  },
  {
    id: 15,
    message:
      "listen to your inner guidance. obviously, right? you already know what you need to do or what you don't need to do. if everything seems chaotic now, find a quite place or turn on your favourite music and sit still for a moment. the answer will pop up. trust me.",
  },
  {
    id: 16,
    message:
      "integrate your shadow. what are you hiding? it's time to shine light on those hidden parts of your mind where you keep your 'weird' thoughts. possibly, they are not actually weird, you just kept them hidden for too long. ",
  },
  {
    id: 17,
    message:
      "follow your truth. you are going to be fine. you are going to get a good meal, brush your teeth, get a good night sleep, write your thoughts on a piece of paper, talk to someone in your life who you love, have a glass of water, take a walk, take a deep breath of fresh air and then another one. take care of yourself doing those little things, drown in joy and love that the world is. and you gonne be fine. ",
  },
  {
    id: 18,
    message:
      "there is a concept in physics called constructive interference. it's a fascinating principle that reveals how waves interact with each other. when two wavelengths of equal frequencies align their amplitude is doubled, but whem waves of different frequencies collide, they cancel each other out. this principle extends beyond physics. surrounding yourself with people who resonate with your energy and goals creates a powerful synergy that propels you towards your growth.",
  },
  {
    id: 19,
    message:
      "what are you taking? in other words, what are you allowing into your system? what foods do you eat, what do you watch, what places are you visiting, who are you talking to? this is your intake. and what comes in is what comes out.",
  },
  {
    id: 20,
    message:
      "you are god. this is the secret. god in your own universe. there is infinity within you. the world around you reflects that infinity back to you. this life is to create, but in order to create from the heart, you need to get aquainted with that infinite potential that lies within. first of all, accept that you have it. acknowledge it. feel how it feels. feel how your understanding of yourself expands and new opportunities are coming in.",
  },
  {
    id: 21,
    message:
      "separation is an illusion. borders are imaginary. our planet, for example, operates as an entity, organism, body. It operates as a whole. The elements within that system are interrelated. Your actions have an influence on the rest of the world, creating ripple effects. think about what you are putting out because your actions are so much more influential than you realise. your everyday routine affects the rest of the world because separation is an illusion",
  },
  {
    id: 22,
    message:
      "life is a game. you are being called to understand that the risks you have taken as of late is going to bring the result that you desire. keep treating life as a game. reduce the level of seriousness. listen to the cues that your body is telling you. fill your body with joy.",
  },
  {
    id: 23,
    message:
      "life is absurd, isn't it? the brain is here to comprehend the inner workings of the universe. the best thing to do when you don't know what to do is to do nothing. let the absurdity flourish for it is the fog that's fading away, in time, giving rise to the clarity. just wait and see. ",
  },
  {
    id: 24,
    message:
      "you are free. from now on, let every choice you make be aligned with the vision you have of yourself. let every path, every opportunity be a stepping stone towards your dreams and aspirations. embrace each decision with intention and courage, knowing that you are creating the life you desire. visualise your higher self. you are your higher self now. ",
  },
  {
    id: 25,
    message:
      "what does your soul desire? like, on a very very very deep level, what is it that you truly want? the thing is, only the real desires can manifest. why? because to manifest something, you need the pure energy from your heart, the love energy. and this love energy is released as a response to something that you want from your heart. our hearts are more powerful than our brains.",
  },
  {
    id: 26,
    message:
      "all things exist right here right now in the present moment. the past does not receide away into history, it's in fact projecting forward, creating our future. look over the entire day, and rewrite it to make it conform to the ideal day you wish you had experienced. do it over and over again until it feels real.",
  },
  {
    id: 27,
    message:
      "who were you in your past lives? technically, the life you are experiencing now is a continuation of everything else your soul experienced in your past lives. as such, the things that get you inspired today, the things you want to achieve, the things you love and attracted to are the things that your soul craves because your soul is on a path of fulfilling its destiny. listen closely.",
  },
  {
    id: 28,
    message:
      "what do you want? the experiences you want are on their way to you. let the life flow. you are called to connect with nature and your inner self, find balance, sit still, be calm.",
  },
  {
    id: 29,
    message:
      "all you do is art, when you do it from love. you create as you speak, when you speak from your heart. feel the connection between your thoughts and your energetic frequency to become a powerful creator.",
  },
  {
    id: 30,
    message:
      "let the energy flow through your body... take a risk of fully trusring your impulses today, whether logical or not. it's not a time to plan everything 1000 steps ahead, but it's a time to take 1 step that feels right, trusting that every next step in the desired direction will reveal itself as you go. ",
  },
  {
    id: 31,
    message:
      "how do you choose? you are faced with millions of choices each day: the route to take, the time to wake up, the photos to take, the messages to send, the ideas to accept, the new knowledge to uncover, the people to talk to and the people to avoid. you might be making similar choices, but if you'd like changes, then start with changing something small, not even in the things you choose, but in an approach you take when choosing those things.",
  },
  {
    id: 32,
    message:
      "you are transforming. you are out of your comfort zone. you have already set up your destination and taking all the necessary steps. remember to give your body and mind some rest for rest is as essential as work in moving forward.",
  },
  {
    id: 33,
    message:
      "paradigme shift is coming. some things are not meant to be known intellectually but are meant to be experienced through intuition. trust that you have access to higher wisdom and know that mysteries will be revealed in no time.",
  },
  {
    id: 34,
    message:
      "you are the cause. this is a wonderful time to give birth to new ideas and experiences. your life is rich and ripe with possibility that will manifest for the highest good.",
  },
  {
    id: 35,
    message:
      "the world revolves around you. everything is possible and everything you need to know is illumninated. projects come to fruition.",
  },
  {
    id: 36,
    message:
      "you can't predict life. following the rules right now is exactly what you shhould be doing. people in authority will come to your aid and give you good advice when you ask for it.",
  },
  {
    id: 37,
    message:
      "god in your own universe, you are never alone or helpless. the force that guides the stars guides you,too.",
  },
  {
    id: 38,
    message:
      "oh, is it? think again. jokes... it's best if you don't think at all for this little brain of yours only gets you stuck in circles of repeating paterns. seriously, it's time to get out. get out of your mind, repeating situstions, your room, those things you hear each day, those faces you are fed up with, all the unressolved fs. f it. let it all go. feel the flow.",
  },
  {
    id: 39,
    message:
      "act on your emotions, what are they? your emotions? some say, there are only 6 emotions that exist: anger, fear, joy, shame, sadness, surprise. which one have you experienced most recently? emotions store plenty of energy and the way to use this energy is to locate that emotion in your body, feel it, name it, own it, experience it, act on it, let it float.",
  },
  {
    id: 40,
    message:
      "feel what feels. you have feelings because, beyond a soul, you have a human physical suit. it's a temporal thing to be able to feel. whatever feeling it is, is a unique experinece. different people experience feeligns differently, so, your feeligns are wonderful because they are a limited edition, a unique exeprience. all feelings pass, eventually, especially after you feel them in your body.",
  },
  {
    id: 41,
    message:
      "world changes when you change, crazy, isn't it? crazy, how the extremely large world issues are solved by little changes within individuals and their habits.",
  },
  {
    id: 42,
    message:
      "you are powerful. well, you know that already. you are the sun. shine your light. your power is your light. ever noticed how others are magnetized to your light?",
  },
  {
    id: 43,
    message:
      "you are a bunch of energy going through a human experience. do you see it? or do you feel it? do you like it? whatever it is, you chose it. you chose that human path, that experience, those traits, those goals, aspirations, dreams, desires. you, as a soul, chose to be you, as a human form. the goal is to go through, experince. literally, to experience is the goal. and it is your choice how to perceieve that experience: as a game or as suffering.",
  },
  {
    id: 44,
    message:
      "follow your vision. for you have it. your unique vision that is so needed by others. especially when your views differ from the norm. it is your view that is correct. and it is the norm that is obscured.",
  },
  {
    id: 45,
    message:
      "it's already yours. that thing you want is already yours. look at your present moment as a page in a book. the rest of the book is already written, the thing you want is already in the book. your present moment is an essential component of that book.",
  },
  {
    id: 46,
    message:
      "your thoughts are magnetic. have you noticed? the thoughts you think attract the reality in front of you. i'd even say more. the thoughts you have a direct relation to everything you experience. seriously, change 1 thing you think, like, 1 opinion you posess about something, change it, just for banters, and see what happens.",
  },
  {
    id: 47,
    message:
      "the world responds to what you are. i like to look at life as a constant conversation between myself and god/universe/reality/creator. you say something, the world responds to it. you respond to the world. the world responds to you. the next time you respond, do it from the heart, see what happens.",
  },
  {
    id: 48,
    message:
      "what are you? a human? an adult? a child? husband/wife/partner/worker/citizen, etc? what are you beyond that? your desires? your thoughts? or the thing that observes all that?",
  },
  {
    id: 49,
    message:
      "do you feel your strong foundation? not in a physical material earthy temporal stability, but rather a commitment to owning your own strength and knowing that no matter what happens, you will stay solid and true to your own perspective on life? ",
  },
  {
    id: 50,
    message:
      "do you feel alive? do you feel the energy coming to you, electrifying you? do you feel like screaming at the top of your lungs how much you love this life and do you feel like wishing every person to be the happiest they could be and do you feel like beeing so free that any deisre that pops up within your being is beign manifested that very moment? do you feel live going through your veins? do you feel alive? ",
  },
  {
    id: 51,
    message:
      "show it. show what you've got. show it to yourself, show it to people around, show it to me. i want to see it. what is your biggest talent? what is it you feel you are worst at? show it all. i want to see it.",
  },
  {
    id: 52,
    message:
      "games with imagination. play. play, play play. that's all you do each day. you play with this world, creating images and experineces just by the powers of your mind. keep playing!",
  },
  {
    id: 53,
    message:
      "play your game. with power. with attitude. with your unique ways. play your game, your own game, set your rules, the rules you want to set, the rules you want to play by. your rules are divine in your world. you are given your world so you can set your rules. that's how life works.",
  },
  {
    id: 54,
    message:
      "love is universal but towards yourself. what the heck, ha? what about loving your partner? what about loving the world? no, sorry, to love something you need to know it and you'll never know anything but yourself. there are also good news - you are containing a universe within yourself, so when you love yourself, you love your universe. the world around you in the reflection of that universe within. so this is how it works. to love yourself is to love your universe which means the love is universal but towards yourself.",
  },
  {
    id: 55,
    message: "god is love. and love is god.",
  },
  {
    id: 56,
    message:
      "where are we going? what's your direction? where is that vector of desire is pointing you? where is that little compass showing to? go there. that's your true path. that's the only thing that matters. everything else fades away but your vector is always poiting somwehre, that's where to go, that's where to be, that is your destiny.",
  },
  {
    id: 57,
    message:
      "state of flux. it's like... everything is constantly moving, such a paradox. everything is in a state of flux. really, sit still, observe how everything moves by itself. feel the movement of life, the inner workings of the universe moving things around... sit, listen, feel it... how does it feel to feel it? ",
  },
  {
    id: 58,
    message:
      "you are changing, congrats, that's the best thing you could be doing, that's the best activity to indugle in. change. you are flowing with life in a boat, you have a course and you know, even if only on a soul level, you know where you are going. transformation. let yourself mould into different shapes and forms, let yourself be flexy, feel the satisfaction from the change. it's the most satisfying thing that there is.",
  },
  {
    id: 59,
    message:
      "you are creating your reality. right now. you are in that phase. you are setting the pieces and puzzles and shapes and forms into place. look around, what fits in? what doesnt? through away everything that's asking to go. put something new in place. you are the designer.",
  },
  {
    id: 60,
    message:
      "your routine actions have ripple effects. everything does. but your routine actions! oh! what do you do every day in the same exact fashion? change 1 thing by 1 little tiny miniscule bit.... watch how it affects the rest... now think how easy it is to change our lives if 1 little chnage can produce such a grand effect?",
  },
  {
    id: 61,
    message:
      "who are you today? we might not be free to do it, by the systems of thought and legally binding agreements, but we are actually, in reallity, always somebody new every day. who were you yesterday? who are you today? who are you going to be next year? cherish the now... for you'll be a different person tomorrow. if you don't see it, yet, you will.",
  },
  {
    id: 62,
    message:
      "strength. be the strength with you. be you the strength. you have it now. it's yours.",
  },
  {
    id: 63,
    message:
      "try again, or don't try, it's all the same. regardless of what you think you should or shouldn't do, when the impulse come, you won't question it. you won't need to try. you'll just do it. so, for now, until that impulse came, relax, enjoy, it's time to just be.",
  },
  {
    id: 64,
    message: "life meant to be lived, not thought, ha.",
  },
  {
    id: 65,
    message: "1414",
  },
  {
    id: 66,
    message: "1221",
  },
  {
    id: 67,
    message: "222",
  },
  {
    id: 68,
    message: "111111111111111111",
  },
  {
    id: 69,
    message:
      "money is energy that flows from you into the world and then flows back to you because what you give is what you get. the other thing is that the enegry you give away comes back to you in different forms: one of the is energy. but for everythign in life is enegry, the energy you give away comes back to you multipled, in different shapes and forms. notice what you've got. notice what people give you 'for free'...",
  },
  {
    id: 70,
    message:
      "you are energy. you have an ocean, a universe, a multiverse, a black hole of energy inside of yourself. it asks to be released, to be put in action... let it free, it's going to guide you to places.",
  },
  {
    id: 71,
    message:
      "abundance is you. you have options. what do you want to receive? what is it that you are choosing to give and thereofre to get? it's anything you choose. anything. keep your eyes on the prise. make fierce moves.",
  },
  {
    id: 72,
    message:
      "ego or shadow? which one is calling to be explored? a little hint, the ego is how you think about yourself being perieced by others, it's your pritective shell. the shadow is what you prefer to hide, what you feel uncofortable telling others, what you might even try to hide from yourself by telling yourself you should stop doing something. both, ego and shadow are the intergal parts of who you are. send love to whichever one is calling to be seen today.",
  },
  {
    id: 73,
    message:
      "what fruit would you be? pear? banana? it's not that it makes a difference, but it's interesting to think about it? clarity is everything. get clear on how you relate to the world. through clarity, you create the best life by connecting the world in your own way. what things are a vibrational match?",
  },
  {
    id: 74,
    message:
      "create. you are ready for it. create what asks to be created. there is a force within you that want to come out into the physcial reality. this force is like a potential for something solid. feel that force inside yourself and let it come out by using it to create something real.",
  },
  {
    id: 75,
    message:
      "what's your thing? we all have a thing. what is yours? could be something you use to identify yourself... or the first thing you think about when you wake up each morning. or the thing you dream about when falling asleep. or maybe a repetitive thought that's been coming up for the past 10 years. what is that thing? don't answer straight away, let the question sink in. that's where your answer lies.",
  },
  {
    id: 76,
    message:
      "nice day? how do you define it? here's what you should do, if you ask me, the next time you've got spare 5 minutes, outline the main themens that construct a 'nice day' for you. how does it look like? what is the weather? who do you meet that day? just write it down, see what happens.",
  },
  {
    id: 77,
    message:
      "you are lucky, believe it or not. your luck is always with you, wherever you go. have you noticed how every time you really need help, it's there? have you noticed how you yourself manage to take yourself out of the most difficult situations? some people call it luck, others call it allignment, you might call it something else, doesn't matter how you call it, that THING is always with you, your guiding star, your power.",
  },
  {
    id: 78,
    message:
      "make a wish. one, two, three, right now, make it. it's the time. the magic of life is here to make it happen. let it happen.",
  },
  {
    id: 79,
    message:
      "go with the flow, flow flow flow, flow like a river flows. i like everything that flows, do you? the changes, the movement, the transmutation of one thing into the other, the constant exchange of energy eveyrthing with everything. you are called to notice the flow, today. just obsere, how everything shifts it's form. look from a far, or look closely, doesn't matter, it all always shifts and turns.",
  },
  {
    id: 80,
    message:
      "tough love is also love. the more it hurts, the more it allows you to transmute into a new form.",
  },
  {
    id: 81,
    message:
      "do you believe in love?  for real real? do you wait for it? do you give it away? you are called to define what love is or is not for you. contemplate. don't give an answer straight away. if, the words are not coming to help, what is love on the level of the feeling? how does love feel? does it feel like being alive?",
  },
  {
    id: 82,
    message:
      "do you feel in whole? you are moving in time, as a one whole piece. do you feel that wholeness? that everything that constitutes you is 1? if there is a part of you that feels disjointed, what is it that separates the rest of you from that part? what we are doing now is a shadow integration. embracing all sides of you as divine.",
  },
  {
    id: 83,
    message:
      "you have now. i don't need to give you any advices, for my thing is to cause a transformation. the message is clear: feel. the feeling releases energy in the present moment that is really just asking to be used to cause an inner transformation. the feeling you feel is transforming your life in a way you really want it to be transformed.",
  },
  {
    id: 84,
    message:
      "the world is your oyster, and you are free to eat it, connect with it, observe it, discover all parts of it, interact with it, talk to it, look at it, take a photo of it, study it, read it, imagine it, share it, blend with it into 1, add light to it, take it seriously, take it lightly, ask it who you are, find love in it, etc.",
  },
  {
    id: 85,
    message:
      "good omens. as you are a part of the Being, your desires from the heart (the connection of the physical and eternal) are the desires of the Being (the eternal). You are meant to go to where your desire is leading you to go, that's all to know for now.",
  },
  {
    id: 86,
    message:
      "get higher. have you ever felt too naked in front of your own awareness? self-conscious, people call it. that's the point of change. when it feel scary and uncomfortable and it feels like wanting to find a place to hide. just feel it, it's going to do its job and leave you refreshed. your job is to observe.",
  },
  {
    id: 87,
    message:
      "the city you love is where you are meant to be. it is where you will be for I see your future. how will you be there? why? what is going to bring you there? how are you going to achieve it? the process is going to be laid out 1 step at a time.",
  },
  {
    id: 88,
    message: "fortune teller",
  },
  {
    id: 89,
    message: "who needs it?",
  },
  {
    id: 90,
    message: "creative phase",
  },
  {
    id: 91,
    message: "what's your role?",
  },
  {
    id: 92,
    message: "healing is transforming",
  },
  {
    id: 93,
    message: "every part has value",
  },
  {
    id: 94,
    message: "war is an illusion",
  },
  {
    id: 95,
    message: "you have a vision",
  },
  {
    id: 96,
    message: "are you?",
  },
  {
    id: 97,
    message: "self-esteem",
  },
  {
    id: 98,
    message: "it's personal",
  },
  {
    id: 99,
    message: "find your purpose",
  },
  {
    id: 100,
    message: "social butterfly",
  },
  {
    id: 101,
    message: "social animal",
  },
  {
    id: 102,
    message: "conforming to norms",
  },
  {
    id: 103,
    message: "open up",
  },
  {
    id: 104,
    message: "vulnerability is strength",
  },
  {
    id: 105,
    message: "hear me out",
  },
  {
    id: 106,
    message: "burst",
  },
  {
    id: 107,
    message: "be so?",
  },
  {
    id: 108,
    message: "100 years of solitude",
  },
  {
    id: 109,
    message: "a moveable feast",
  },
  {
    id: 110,
    message: "tropic of cancer",
  },
  {
    id: 111,
    message: "take what's yours",
  },
  {
    id: 112,
    message: "it seems that the only thing in my power is my vibration",
  },
  {
    id: 113,
    message: "move your body to the music you love",
  },
  {
    id: 114,
    message: "you are my finest piece of cake",
  },
  {
    id: 115,
    message: "golden days are now",
  },
  {
    id: 116,
    message: "success is inevitable",
  },
  {
    id: 117,
    message: "who is the judge?",
  },
  {
    id: 118,
    message: "romanticise your life",
  },
  {
    id: 119,
    message: "say bye, it's time for the next chapter",
  },
  {
    id: 120,
    message: "why haven't you done that before?",
  },
  {
    id: 121,
    message: "forgotten something?",
  },
  {
    id: 122,
    message: "what's your biggest dream?",
  },
  {
    id: 123,
    message: "this is a new recipe",
  },
  {
    id: 124,
    message: "the question is what is your measurnment",
  },
  {
    id: 125,
    message: "dance!",
  },
  {
    id: 126,
    message: "the calibrate power increases logarithmically",
  },
  {
    id: 127,
    message: "too much intellectual effort",
  },
  {
    id: 128,
    message: "heisenberg principle",
  },
  {
    id: 129,
    message: "apply the rules",
  },
  {
    id: 130,
    message: "no better time than right now",
  },
  {
    id: 131,
    message:
      "a warrior fights against the world, the magician creates the world",
  },
  {
    id: 132,
    message: "you are creating your world, i hope you know your power",
  },
  {
    id: 133,
    message: "notice the little things",
  },
  {
    id: 134,
    message: "breathe in the luck",
  },
  {
    id: 135,
    message: "breathe out the stress",
  },
  {
    id: 136,
    message:
      "how to differentiate a goal set from the heart from the one set by the societal norms?",
  },
  {
    id: 137,
    message: "get your priorities straight",
  },
  {
    id: 138,
    message: "heal by means of relaxation and enjoyment",
  },
  {
    id: 139,
    message: "you are so strong",
  },
  {
    id: 140,
    message: "what do you want to be?",
  },
  {
    id: 141,
    message: "have something sweet, like a hug",
  },
  {
    id: 142,
    message: "give a kiss to somebody you love",
  },
  {
    id: 143,
    message: "tell them how you feel",
  },
  {
    id: 144,
    message: "if you think of me, i most probably think of you",
  },
  {
    id: 145,
    message: "play this beautiful game called life",
  },
  {
    id: 146,
    message: "keep going",
  },
  {
    id: 147,
    message: "look at the stars, they have a message for you",
  },
  {
    id: 148,
    message: "love is in",
  },
  {
    id: 149,
    message: "sunbathing is work",
  },
  {
    id: 150,
    message:
      "the only thing in your control is your intention: is it love or is it..?",
  },
  {
    id: 151,
    message: "does it feel like the end of the beginning?",
  },
  {
    id: 152,
    message: "text me",
  },
  {
    id: 153,
    message: "easy to find out the truth of the matter",
  },
  {
    id: 154,
    message: "some changes take time to materialise",
  },
  {
    id: 156,
    message: "as long as you go forward, you go",
  },
  {
    id: 157,
    message:
      "technically, you dont do things, you observe your human do things",
  },
];

export default FeelingLuckyList;
