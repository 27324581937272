import React from "react";
import { MeshDistortMaterial } from "@react-three/drei";

const DistortBall = ({ position, distort, speed, hoverColor }) => {
  const [onHover, setOnHover] = React.useState(false);
  return (
    <mesh
      castShadow
      onPointerOver={(e) => setOnHover(true)}
      onPointerOut={(e) => setOnHover(false)}
      position={position}
      visible
    >
      <sphereGeometry
        attach="geometry"
        args={onHover ? [1, 50, 50] : [0.6, 50, 50]}
      />
      <MeshDistortMaterial
        color={onHover ? hoverColor : "#ffffff"}
        attach="material"
        distort={onHover ? distort + 0.5 : distort} // Strength, 0 disables the effect (default=1)
        speed={onHover ? speed + 0.5 : speed} // Speed (default=1)
        roughness={0}
      />
    </mesh>
  );
};

export default DistortBall;
