import { Link } from "react-router-dom";
import FeelingLuckyList from "./list/FeelingLuckyList";
import { useState } from "react";

const TextSelfinder = () => {
  const [randomMessage, setRandomMessage] = useState("");

  const handleClick = () => {
    const randomIndex = Math.floor(Math.random() * FeelingLuckyList.length);
    const selectedMessage = FeelingLuckyList[randomIndex];
    setRandomMessage(selectedMessage.message);
  };
  return (
    <>
      <div className="buttonsTop">
        <button className="feelingLuckyButton" onClick={handleClick}>
          feeling lucky
        </button>
        {randomMessage && <div className="randomMessage">{randomMessage}</div>}

        <Link to="/levels" className="levelsButton">
          levels
        </Link>
      </div>
    </>
  );
};

export default TextSelfinder;
